<template>
    <!-- <van-pull-refresh class="pull-refresh" v-model="refreshing" @refresh="onRefresh"> -->
    <div class="MyOrderPage">
        <div style="padding: 1.7065vh 0;">
            <div class="head container">
                <div class="title">
                    <span class="iconfont icon-a-iconpay_huaban1fuben38"></span>
                    <span class="type">
                    <span>{{ data.order_state }}</span>
                </span>
                </div>
                <p class="info">卖家截止确认时间：{{ data.CANCEL_DTT }}，如超过确认截止时间，该订单会自动取消</p>

                <span class="popup" v-if="data.deal" @click="orderNotify">呼叫对方</span>
            </div>

            <!-- 订单信息 -->
            <div class="order container">
                <p class="title">
                    <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                    <span>订单信息</span>
                </p>
                <p class="item">
                    <span>订单编号：</span>
                    <span>{{ data.ORDER_NO_SUB }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.ORDER_NO_SUB"></span>
                </p>
                <p class="item">
                    <span>下单时间：</span>
                    <span>{{ data.CREATE_DATE }}</span>
                </p>
                <p class="item">
                    <span>卖方昵称：</span>
                    <span>{{ data.NICK_NAME }}</span>
                </p>
                <p class="item">
                    <span>购买总数：</span>
                    <span>{{ data.AMT }}</span>
                </p>
                <p class="item">
                    <span>收款方式：</span>
                    <span>{{ data.bank_type }}</span>
                </p>
            </div>

            <!-- 卖家收款信息 -->
            <div class="seller container" v-if="data.STATE_CHECK === '1'">
                <p class="title">
                    <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                    <span>卖家收款信息</span>
                </p>

                <p class="item">
                    <span>姓名：</span>
                    <span>{{ data.REAL_NAME }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.REAL_NAME"></span>
                </p>

                <!-- 如果是微信，就不显示账号 -->
                <p class="item" v-if="data.BANK_TYPE !== '2'">
                    <span>卖家账号：</span>
                    <span>{{ data.SELL_PAY_ACCOUNT }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.SELL_PAY_ACCOUNT"></span>
                </p>

                <!-- 银行卡才展示 -->
                <p class="item" v-if="data.BANK_TYPE === '1'">
                    <span>银行：</span>
                    <span>{{ data.SELL_PAY_BANK }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.SELL_PAY_BANK"></span>
                </p>

                <!-- 银行卡才展示 -->
                <p class="item" v-if="data.BANK_TYPE === '1'">
                    <span>开户行：</span>
                    <span>{{ data.SELL_QR_CODE }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.SELL_QR_CODE"></span>
                </p>

                <!-- 微信、支付宝才展示 -->
                <p class="item" v-if="data.BANK_TYPE === '2' || data.BANK_TYPE === '3'">
                    <span>收款码：</span>
                    <img class="code" :src="fileConfig + data.SELL_QR_CODE" @click="showImg(fileConfig + data.SELL_QR_CODE)" />
                </p>
            </div>

            <!-- 买家付款信息 -->
            <div class="buyer container" v-if="data.STATE_TRANS === '1'">
                <p class="title">
                    <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                    <span>买家付款信息</span>
                </p>
                <p class="item">
                    <span>姓名：</span>
                    <span>{{ data.BUY_PAY_NAME }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.BUY_PAY_NAME"></span>
                </p>

                <!-- 银行卡才展示 -->
                <p class="item" v-if="data.BANK_TYPE === '1'">
                    <span>银行：</span>
                    <span>{{ data.BUY_PAY_BANK }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.BUY_PAY_BANK"></span>
                </p>

                <!-- 如果是微信，就不显示账号 -->
                <p class="item" v-if="data.BANK_TYPE !== '2'">
                    <span>买家账号：</span>
                    <span>{{ data.BUY_PAY_ACCOUNT }}</span>
                    <span class="iconfont icon-a-iconpay_huaban1fuben33 copy" @click="clipboardText('.copy')" :data-clipboard-text="data.BUY_PAY_ACCOUNT"></span>
                </p>
            </div>

            <!-- 订单状态 -->
            <div class="status container">
                <p class="title">
                    <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                    <span>订单状态</span>
                </p>

                <van-steps direction="vertical" active-color="#38f" :active="active">
                    <van-step>
                        <h3>买家创建订单</h3>
                        <p>{{ data.CREATE_DATE }}</p>
                    </van-step>

                    <van-step>
                        <h3 :class="{ cancel: data.STATE_CHECK === '0' }">{{ data.STATE_CHECK === '1' ? '卖家已确认' : '待卖家确认' }}</h3>
                        <p>{{ data.STATE_CHECK === '0' ? '' : data.DTT_CHECK }}</p>
                    </van-step>

                    <van-step>
                        <h3 :class="{ cancel: data.STATE_TRANS === '0' }">{{ data.STATE_TRANS === '1' ? '买家已转账' : '待买家转账' }}</h3>
                        <p>{{ data.STATE_TRANS === '0' ? '' : data.DTT_TRANS }}</p>
                    </van-step>

                    <van-step>
                        <h3 :class="{ cancel: data.STATE_PAYCON === '0' }">{{ data.STATE_PAYCON === '1' ? '卖家已打币' : '待卖家打币' }}</h3>
                        <p>{{ data.STATE_PAYCON === '0' ? '' : data.DTT_PAYCON }}</p>
                    </van-step>

                    <van-step v-if="data.ORDER_STATE === '2'">
                        <h3>已取消</h3>
                        <p>{{ data.DONE_DTT }}</p>
                    </van-step>
                    <van-step v-if="data.ORDER_STATE === '1'">
                        <h3>交易成功</h3>
                        <p>{{ data.DONE_DTT }}</p>
                    </van-step>
                </van-steps>
            </div>

            <!-- 买家下完单 ：
					卖家：取消订单，确定订单
					买家：取消订单 -->
            <div class="button" v-if="data.deal && data.STATE_CHECK === '0'">
                <van-button class="btn" type="primary" round v-if="data.IM_BUY_USER === '0'" @click="sellCancel">取消订单</van-button>
                <van-button class="btn" type="primary" round v-if="data.IM_BUY_USER === '0'" @click="sellSure">确定订单</van-button>

                <van-button class="btn" type="primary" round v-if="data.IM_BUY_USER === '1'" @click="buyCancel">取消订单</van-button>
            </div>

            <!-- 卖家确定订单：
					卖家：无
					买家：取消订单，我已转账 -->
            <div class="button" v-else-if="data.deal && data.STATE_CHECK === '1' && data.IM_BUY_USER === '1' && data.STATE_TRANS === '0'">
                <van-button class="btn" type="primary" round @click="buyCancel">取消订单</van-button>
                <van-button class="btn" type="primary" round @click="buyAccount">我已转账</van-button>
            </div>

            <!-- 买家已转账：
					卖家：暂停打币，确认打币
					买家：无 -->
            <div class="button" v-else-if="data.deal && data.STATE_TRANS === '1' && data.IM_BUY_USER === '0' && data.STATE_PAYCON === '0'">
                <van-button class="btn" type="primary" round @click="sellPause">暂停打币</van-button>
                <van-button class="btn" type="primary" round @click="sellAffirm">确认打币</van-button>
            </div>

            <!-- 买家下完单 => 卖家：确定订单 -->
            <van-action-sheet v-model:show="show" title="请选择收款信息">
                <div class="content">
                    <van-radio-group v-model="bankID">
                        <van-cell-group>
                            <van-cell v-for="item in bankList" :key="item.ID" :title="`${item.PAY_BANK} - ${item.PAY_NAME}： ${item.PAY_ACCOUNT}`" clickable @click="checkBank(item.ID)">
                                <template #right-icon>
                                    <van-radio :name="item.ID" />
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </van-action-sheet>

            <van-dialog v-model:show="showDrawpass" title="请输入支付密码" show-cancel-button @confirm="confirm">
                <van-field v-model="drawpass" type="password" placeholder="请输入支付密码" />
            </van-dialog>

            <!-- 卖家确定订单 => 买家：我已转账 -->
            <van-action-sheet v-model:show="pay_show" title="请选择付款信息">
                <div class="content">
                    <van-radio-group v-model="pay_bankID">
                        <van-cell-group>
                            <van-cell v-for="item in pay_bankList" :key="item.ID" :title="`${item.PAY_BANK} - ${item.PAY_NAME}： ${item.PAY_ACCOUNT}`" clickable @click="pay_checkBank(item.ID)">
                                <template #right-icon>
                                    <van-radio :name="item.ID" />
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </van-action-sheet>
        </div>
    </div>
    <!-- </van-pull-refresh> -->
</template>

<script>
import { reactive, toRefs, watch, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { GetBuyOrderDetail, getBankList, SellCheckOrder, BuyCancelOrder, BuyCheckOrder, SellTranOrder, OrderNotify, SellPauseTranCoin } from '@/api'
import { Dialog, Toast, ImagePreview } from 'vant'
import md5 from 'js-md5'
import { clipboardText } from '@/assets/js/common.js'
import BScroll from '@better-scroll/core'

export default {
    setup() {
        const state = reactive({
            refreshing: false,
            fileConfig: '',

            subID: 0,
            rd: '',
            data: {},
            active: 0,

            bankList: [],
            show: false,
            bankID: '',

            pay_bankList: [],
            pay_show: false,
            pay_bankID: '',

            showDrawpass: false,
            drawpass: '',

            BScroll: null
        })

        const router = useRouter()
        const route = useRoute()
        const store = useStore()

        state.fileConfig = store.state.fileConfig
        state.subID = route.query.subID
        state.rd = route.query.rd

        const getData = () => {
            GetBuyOrderDetail(state.subID).then(res => {
                let data = res.data

                if (data.ORDER_STATE === '0') data.order_state = '交易中'
                if (data.ORDER_STATE === '1') data.order_state = '交易成功'
                if (data.ORDER_STATE === '2') data.order_state = '交易取消'
                if (data.ORDER_STATE === '3') data.order_state = '暂停打币'

                if (data.ORDER_STATE === '0' || data.ORDER_STATE === '3') {
                    data.deal = true
                }

                if (data.BANK_TYPE === '1') data.bank_type = '银行'
                if (data.BANK_TYPE === '2') data.bank_type = '微信'
                if (data.BANK_TYPE === '3') data.bank_type = '支付宝'

                // 卖家确认订单 < 买家转账确认 < 卖家打币确认
                if (data.STATE_CHECK === '1') state.active = 1
                if (data.STATE_TRANS === '1') state.active = 2
                if (data.STATE_PAYCON === '1') state.active = 3
                if (data.ORDER_STATE === '1' || data.ORDER_STATE === '2') state.active = 4

                state.data = data
                state.refreshing = false
                nextTick(() => {
                    setTimeout(() => {
                        state.BScroll.refresh()
                    }, 400)
                })
            })
        }
        getData()

        watch(route, () => {
            if (route.query.rd !== state.rd) {
                state.subID = route.query.subID
                state.rd = route.query.rd
                getData()
            }
        })

        // WebSocket
        window.connection.off('RefreshMyOrderData')
        window.connection.on('RefreshMyOrderData', ({ subId }) => {
            if (state.subID === subId) {
                getData()
            }
        })

        const sellCancel = () => {
            SellCheckOrder({
                subID: state.subID,
                state: 0,
                bankID: ''
            }).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
            })
        }

        const sellSure = () => {
            // state.bankID = ''

            //  这里需要判断 BANK_TYPE，调接口，看有没有多个同样的收款方式，如果大于 2，选一个
            getBankList().then(res => {
                state.bankList = res.data.filter(i => i.BANK_TYPE === state.data.BANK_TYPE)

                if (state.bankList.length > 1) {
                    state.show = true
                } else {
                    sellCheckOrder()
                }
            })
        }
        const checkBank = bankID => {
            state.bankID = bankID
            state.show = false

            sellCheckOrder()
        }
        const sellCheckOrder = () => {
            SellCheckOrder({
                subID: state.subID,
                state: 1,
                bankID: state.bankID
            }).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
                OrderNotify(state.subID)
            })
        }

        const buyCancel = () => {
            BuyCancelOrder(state.subID).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
            })
        }

        const buyAccount = () => {
            //  这里需要判断 BANK_TYPE，调接口，看有没有多个同样的收款方式，如果大于 2，选一个
            getBankList().then(res => {
                state.pay_bankList = res.data.filter(i => i.BANK_TYPE === state.data.BANK_TYPE)

                if (state.pay_bankList.length > 1) {
                    state.pay_show = true
                } else {
                    let bankID = state.pay_bankList[0].ID
                    buyCheckOrder(bankID)
                }
            })
        }
        const pay_checkBank = bankID => {
            state.pay_bankID = bankID
            state.pay_show = false

            buyCheckOrder(bankID)
        }
        const buyCheckOrder = bankID => {
            BuyCheckOrder({
                subID: state.subID,
                bankID
            }).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
                OrderNotify(state.subID)
            })
        }

        const sellPause = () => {
            SellPauseTranCoin(state.subID).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
            })
        }

        const sellAffirm = () => {
            state.showDrawpass = true
        }

        const confirm = () => {
            SellTranOrder({
                subID: state.subID,
                drawpass: md5(state.drawpass)
            }).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
                getData()
                OrderNotify(state.subID)
            })
        }

        const showImg = src => {
            ImagePreview([src])
        }

        const orderNotify = () => {
            OrderNotify(state.subID).then(res => {
                if (res.status) {
                    Toast.success('操作成功！')
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        const onRefresh = () => {
            state.refreshing = true

            getData()
        }

        const initBScroll = () => {
            state.BScroll = new BScroll('.MyOrderPage', {
                click:true,
                tap:true,
                probeType: 3
            })
            state.BScroll.on('contentChanged', (content) => {
                console.log('--- newContent ---')
                console.log(content)
            })
            state.BScroll.on('scroll', () => {
                console.log('scrolling-')
            })
            state.BScroll.on('scrollEnd', () => {
                console.log('scrollingEnd')
            })
        }

        onMounted(() => {
            initBScroll()
        })

        return {
            ...toRefs(state),
            router,
            sellCancel,
            sellSure,
            checkBank,
            buyCancel,
            buyAccount,
            sellPause,
            sellAffirm,
            confirm,
            pay_checkBank,
            showImg,
            orderNotify,
            clipboardText,
            onRefresh
        }
    }
}
</script>

<style lang="scss" scoped>
.pull-refresh {
    min-height: calc(100vh - 46px);
    background: #f2f2f2;
}

.MyOrderPage {
    overflow: auto;
    background: $background;

     .container {
        box-sizing: border-box;
        width: w(345);
        background: #ffffff;
        border-radius: 8px;
        margin: h(12) auto 0;
        padding: h(15) w(21) h(24) w(15);

        & > p.title {
            font-size: 14px;
            color: #000000;

            & span:nth-of-type(2) {
                margin-left: w(10);
            }
        }

        & > p.item {
            margin-top: h(16);
            line-height: h(16);
            font-size: 12px;
            color: #828282;

            & span:first-child {
                display: inline-block;
                width: w(65);
            }

            & span:nth-of-type(2) {
                color: #333333;
                margin-left: w(15);
            }

            .code {
                margin-left: w(12);
                height: h(83);
            }
        }

        .copy {
            margin-left: w(10);
            font-size: 25px;
            color: #3366ee;
            vertical-align: middle;
        }
    }

    & > div:last-child {
        margin-bottom: h(20);
    }

    div.head {
        color: #f2f2f2;
        background: #3366ee;
        text-align: center;
        position: relative;

        .iconfont {
            font-size: 30px;
            vertical-align: middle;
            margin-right: w(8);
        }

        .type {
            font-size: 16px;
            line-height: 22px;
        }

        .info {
            text-align: left;
        }

        .popup {
            z-index: 9;
            min-width: w(80);
            position: absolute;
            top: h(10);
            right: h(10);
            background: #4cd964;
            border-radius: 10px;
            padding: 3px 6px;
        }
    }

    .order {
    }
    .seller {
    }
    .buyer-proof {
    }
    .buyer {
    }

    .status {
        .cancel {
            color: #bdbdbd;
        }
    }

    .button {
        margin: h(15) 0 h(20);
        display: flex;
        justify-content: space-around;

        .btn {
            width: w(150);
            height: h(40);
        }
    }

    .content {
        padding: 16px 16px h(30);
        text-align: left;

        span {
            font-size: 14px;
            line-height: 109.48%;
            color: #333333;
        }
    }
}
</style>
